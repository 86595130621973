<template>
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 16.5H17V21H12.5V24H17V28.5H20V24H24.5V21H20V16.5ZM21.5 3H9.5C7.85 3 6.5 4.35 6.5 6V30C6.5 31.65 7.835 33 9.485 33H27.5C29.15 33 30.5 31.65 30.5 30V12L21.5 3ZM27.5 30H9.5V6H20V13.5H27.5V30Z"
      fill="#E83F6F"
    />
  </svg>
</template>
