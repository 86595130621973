<template>
  <div class="section-list-items">
    <template v-if="listItemComponent">
      <template v-for="(item, index) in listItems" :key="item.id">
        <component
          :is="listItemComponent"
          :item="item"
          @click.capture="handleAdClick"
          @popup="$emit('popup', $event)"
          :is-geo="isGeo"
        />

        <div
          v-if="index === listItems.length - 5"
          v-intersection
          @intersection="$event.detail.isOnScreen ? $emit('fetchMore') : ''"
          class="fetch-more-element section-list-items__fetch-more-element"
        ></div>
      </template>
    </template>
  </div>
</template>

<script>
import { computed, markRaw, toRef } from "@vue/reactivity";
import { watch } from "vue";

import intersectionDirective from "@/directives/intersection";
import useAnonymusAdGuard from "@/composables/useAnonymusAdGuard";

import ApplicationNannyListItem from "@/components/Application/Nanny/ApplicationNannyListItem.vue";
import OfferNannyListItem from "@/components//Offer/Nanny/OfferNannyListItem.vue";
import { useUserStore } from "@/stores/user";

export default {
  directives: {
    intersection: intersectionDirective,
  },

  props: {
    listItems: Array,
    listItemsClass: {
      type: String,
      required: true,
    },
    listItemsType: {
      type: String,
      required: true,
    },
  },

  emits: ["fetchMore", "popup"],

  setup(props, { emit }) {
    const userStore = useUserStore();

    const isGeo = computed(() => {
      return (
        (userStore.userType === "contractor" &&
          props.listItemsType.toLowerCase() === "offer") ||
        (userStore.userType === "client" &&
          props.listItemsType.toLowerCase() === "application")
      );
    });

    watch(
      () => props.listItems,
      (items) => {
        if (items.length === 0) {
          emit("fetchMore");
        }
      },
      { immediate: true }
    );

    // Setting up component depending on itemTtype (Application/ Offer) and itemClass (Nanny/ PetSitter/ Cleaner/ etc...)
    const listItemComponent = computed(() => {
      switch (props.listItemsType) {
        case "offer":
          switch (props.listItemsClass.toLowerCase()) {
            case "nanny":
              return markRaw(OfferNannyListItem);
            default:
              return undefined;
          }
        case "application":
          switch (props.listItemsClass.toLowerCase()) {
            case "nanny":
              return markRaw(ApplicationNannyListItem);
            default:
              return undefined;
          }

        default:
          return undefined;
      }
    });

    const { canVisitAd } = useAnonymusAdGuard(
      toRef(props, "listItemsType"),
      toRef(props, "listItemsClass")
    );

    const handleAdClick = (event) => {
      if (canVisitAd()) {
        return;
      }
      event.stopPropagation();
      emit("popup", "ad-limit");
      return false;
    };

    return {
      isGeo,
      listItemComponent,
      handleAdClick,
    };
  },
};
</script>

<style scoped>
.section-list-items {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 60px;
}
.section-list-items__fetch-more-element {
  margin: -30px 0;
}
</style>
