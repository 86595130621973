<template>
  <div class="content">
    <LandingNannyClientMobileSectionHero />
    <LandingNannyClientSectionAdvantages />
    <LandingNannyClientMobileSectionAdvantageNotification />
    <LandingNannyClientSectionList />

    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import useIsMobile from "@/composables/useIsMobile";

import NavMobile from "@/components/NavMobile.vue";
import LandingNannyClientMobileSectionHero from "@/components/Landing/Nanny/Client/Mobile/LandingNannyClientMobileSectionHero.vue";
import LandingNannyClientSectionAdvantages from "@/components/Landing/Nanny/Client/LandingNannyClientSectionAdvantages.vue";
import LandingNannyClientMobileSectionAdvantageNotification from "@/components/Landing/Nanny/Client/Mobile/LandingNannyClientMobileSectionAdvantageNotification.vue";
import LandingNannyClientSectionList from "@/components/Landing/Nanny/Client/LandingNannyClientSectionList.vue";

export default {
  components: {
    NavMobile,
    LandingNannyClientMobileSectionHero,
    LandingNannyClientSectionAdvantages,
    LandingNannyClientMobileSectionAdvantageNotification,
    LandingNannyClientSectionList,
  },
  setup() {
    const { isMobile } = useIsMobile();

    return {
      isMobile,
    };
  },
};
</script>

<style scoped></style>
