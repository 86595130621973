<template>
  <base-button
    :to="to"
    :disabled="disabled"
    class="button-chip"
    @click="$emit('click', $event)"
    ><SvgIconsSettings24
  /></base-button>
</template>

<script>
import BaseButton from "@/components/UI/Base/BaseButton.vue";
import SvgIconsSettings24 from "@/components/Svg/Icons/SvgIconsSettings24.vue";

export default {
  components: { BaseButton, SvgIconsSettings24 },
  props: {
    to: {
      type: [String, Object],
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["click"],

  setup() {
    return {};
  },
};
</script>

<style scoped>
.button-chip {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 45px;
  height: 45px;
  border-radius: 50%;
  /* border: var(--circle-button-border); */

  background-color: #fff;
}

.button-chip:hover {
  border: var(--button-chip-hover-border);
  cursor: pointer;
}

/* .button-chip:active {
  border: var(--button-chip-active-border);

  background-color: var(--button-chip-active-bg-color);
}

.button-chip--minus {
  background: center/20px no-repeat url(~@/assets/images/minus_20_20.svg);
}
.button-chip--plus {
  background: center/20px no-repeat url(~@/assets/images/plus_20_20.svg);
}

.button-chip--small {
  width: 50px;
  height: 50px;
}

.circle-button--small.circle-button--minus,
.circle-button--small.circle-button--plus {
  background-size: 16px;
} */

@media (max-width: 1200px) {
  /* BUTTON CIRCLE (+ / -) MOBILE */

  .circle-button--small {
    width: 60px;
    height: 60px;
  }

  .circle-button--small.circle-button--minus,
  .circle-button--small.circle-button--plus {
    background-size: 20px;
  }
}
</style>
