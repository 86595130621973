<template>
  <svg
    viewBox="0 0 630 750"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M466.654 0H0V750H279.527C490 750 630 631 630 373.5C630 115.87 466.654 0 466.654 0Z"
      fill="white"
    />
  </svg>
</template>
