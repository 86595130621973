<template>
  <section class="section-advantages">
    <div class="advantage">
      <SvgIconsNoteAdd36 />
      <div class="wrapper">
        <BaseH2 :text="'Dodaj darmowe ogłoszenie'" />
        <BaseText :text="'Uzyskaj odpowiedzi \nzainteresowanych opiekunek'" />
      </div>
    </div>
    <div class="advantage">
      <SvgIconsSyncAlt36 />
      <div class="wrapper">
        <BaseH2 :text="'Zobacz ankiety niań'" />
        <BaseText :text="'Zadzwoń do tych, \nktóre Ci się spodobały'" />
      </div>
    </div>
  </section>
</template>

<script>
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import SvgIconsSyncAlt36 from "@/components/Svg/Icons/SvgIconsSyncAlt36.vue";
import SvgIconsNoteAdd36 from "@/components/Svg/Icons/SvgIconsNoteAdd36.vue";

export default {
  components: {
    BaseH2,
    BaseText,
    SvgIconsSyncAlt36,
    SvgIconsNoteAdd36,
  },
};
</script>

<style scoped>
.section-advantages {
  width: 100%;
  display: flex;
  justify-content: space-around;
  column-gap: 40px;
}
.advantage {
  display: flex;
  column-gap: 13px;
}
.advantage .wrapper {
  width: 360px;
  display: flex;
  flex-flow: column;
  row-gap: 10px;
}

@media (max-width: 1200px) {
  .section-advantages {
    padding: 70px 10px 70px 20px;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    row-gap: 60px;
  }
  .advantage {
    width: 100%;
    display: flex;
    column-gap: 13px;
  }
  .advantage .wrapper {
    width: 100%;
    display: flex;
    flex-flow: column;
    row-gap: 10px;
  }
}
</style>
