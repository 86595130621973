<template>
  <Transition>
    <HeaderDesktopLanding v-if="isFixed" class="header header-fixed" />
  </Transition>
</template>

<script>
import { ref } from "vue";

import HeaderDesktopLanding from "@/components/Header/HeaderDesktopLanding.vue";

export default {
  components: {
    HeaderDesktopLanding,
  },

  expose: ["fix", "unfix"],

  setup() {
    const isFixed = ref(false);

    const fix = () => {
      isFixed.value = true;
    };

    const unfix = () => {
      isFixed.value = false;
    };

    return {
      isFixed,
      fix,
      unfix,
    };
  },
};
</script>

<style scoped>
.header.header-fixed {
  position: fixed;
  top: 0px;
  z-index: 9999999999999999999;
  background-color: var(--main-bg-color);
  will-change: transform;
}

.v-enter-active {
  transition: transform 0.4s ease-out;
}
.v-leave-active {
  transition: transform 0.1s ease-in;
}
.v-enter-from,
.v-leave-to {
  transform: translateY(calc(-1 * var(--header-default--height)));
}
.v-enter-to,
.v-leave-from {
  transform: translateY(0px);
}
</style>
