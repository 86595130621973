<template>
  <div class="content">
    <HeaderDesktopLandingFixed ref="stickyHeaderRef" />

    <LandingNannyClientDesktopSectionHero />
    <LandingNannyClientSectionAdvantages />
    <LandingNannyClientDesktopSectionAdvantageNotification />
    <LandingNannyClientSectionList
      v-intersection="intersectionOptions"
      @intersection="handleIntersection"
    />
  </div>
</template>

<script>
import { ref } from "vue";

import intersectionDirective from "@/directives/intersection";

import LandingNannyClientDesktopSectionHero from "@/components/Landing/Nanny/Client/Desktop/LandingNannyClientDesktopSectionHero.vue";
import LandingNannyClientSectionAdvantages from "@/components/Landing/Nanny/Client/LandingNannyClientSectionAdvantages.vue";
import LandingNannyClientDesktopSectionAdvantageNotification from "@/components/Landing/Nanny/Client/Desktop/LandingNannyClientDesktopSectionAdvantageNotification.vue";
import LandingNannyClientSectionList from "@/components/Landing/Nanny/Client/LandingNannyClientSectionList.vue";
import HeaderDesktopLandingFixed from "@/components/Header/HeaderDesktopLandingFixed.vue";

export default {
  directives: {
    intersection: intersectionDirective,
  },

  components: {
    LandingNannyClientDesktopSectionHero,
    LandingNannyClientSectionAdvantages,
    LandingNannyClientDesktopSectionAdvantageNotification,
    LandingNannyClientSectionList,
    HeaderDesktopLandingFixed,
  },

  setup() {
    const stickyHeaderRef = ref(null);

    const handleIntersection = (event) => {
      if (stickyHeaderRef.value === null) {
        return;
      }

      if (event.detail.isOnScreen) {
        stickyHeaderRef.value.fix();
      } else {
        stickyHeaderRef.value.unfix();
      }
    };

    const intersectionOptions = {
      threshold: 0,
      root: null,
      rootMargin: `0px 0px -100% 0px`,
    };

    return {
      stickyHeaderRef,
      intersectionOptions,
      handleIntersection,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 100px;
}
</style>
