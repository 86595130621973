<template>
  <section class="section-hero">
    <SvgIllustrationsLandingNannyHeroBgDesktop class="bg" />
    <div class="col-left">
      <div class="title-container">
        <BaseH1 :text="title" :short="true" class="title" />
        <BaseText :text="subtitle" class="subtitle" />
      </div>
    </div>
    <div class="col-right">
      <SvgIllustrationsLandingNannyMain />
    </div>
  </section>
</template>

<script>
import { reactive, toRefs } from "vue";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import SvgIllustrationsLandingNannyMain from "@/components/Svg/Illustrations/SvgIllustrationsLandingNannyMain.vue";
import SvgIllustrationsLandingNannyHeroBgDesktop from "@/components/Svg/Illustrations/SvgIllustrationsLandingNannyHeroBgDesktop.vue";

export default {
  components: {
    BaseH1,
    BaseText,
    SvgIllustrationsLandingNannyMain,
    SvgIllustrationsLandingNannyHeroBgDesktop,
  },

  setup() {
    const state = reactive({
      title: "378 opiekunek \ndo dziecka \nwe Wroclawiu",
      subtitle: "Tablica do samodzielnego poszukiwania \nniań i babysitterów",
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
.section-hero {
  padding-bottom: var(--header-default--height);
  position: relative;
  width: 100%;
  min-height: calc(100vh - var(--header-default--height));
  display: flex;
  column-gap: 40px;
}
.section-hero .bg {
  position: absolute;
  top: calc(-1 * var(--header-default--height));
  right: 35vw;
  z-index: -1;
  width: 65vw;
  height: 100vh;
  min-height: 100%;
}
.section-hero .col-left,
.section-hero .col-right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-hero .col-left .title-container {
  display: flex;
  flex-flow: column;
  row-gap: 90px;
}
.section-hero .title,
.section-hero .subtitle {
  white-space: pre-line;
  text-align: left;
}
</style>
