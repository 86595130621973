<template>
  <svg
    viewBox="0 0 375 387"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 382.311V-1H375V375.99C375 375.99 368 353.5 324 353.5C280 353.5 276.5 409 235 375.99C193.5 342.98 178.263 382.409 150 382.311C112.196 382.181 91 353.5 52.5 353.5C15.981 353.5 0 382.311 0 382.311Z"
      fill="white"
    />
  </svg>
</template>
