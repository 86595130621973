<template>
  <header class="header">
    <div class="header__content">
      <SvgLogoFull />
      <HeaderDesktopLandingContainerMiddle />
      <HeaderDesktopDefaultContainerRight />
    </div>
  </header>
</template>

<script>
import SvgLogoFull from "@/components/Svg/Logo/SvgLogoFull.vue";
import HeaderDesktopLandingContainerMiddle from "@/components/Header/HeaderDesktopLandingContainerMiddle.vue";
import HeaderDesktopDefaultContainerRight from "@/components/Header/HeaderDesktopDefaultContainerRight.vue";

export default {
  components: {
    SvgLogoFull,
    HeaderDesktopLandingContainerMiddle,
    HeaderDesktopDefaultContainerRight,
  },
};
</script>

<style scoped>
.header {
  width: 100vw;
  height: var(--header-default--height);
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__content {
  width: var(--header-default--width);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
</style>
