<template>
  <div class="container-middle header__container-middle">
    <HeaderLinkNav v-if="isAuth === false" :to="link.to" :label="link.label" />
  </div>
</template>

<script>
import { computed } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

import {
  ROUTE_LANDING_NANNY_CLIENT_NAME,
  ROUTE_LANDING_NANNY_CONTRACTOR_NAME,
} from "@/router/constants";

import HeaderLinkNav from "@/components/Header/HeaderLinkNav.vue";
import useAuth from "@/composables/useAuth";

export default {
  components: {
    HeaderLinkNav,
  },

  setup() {
    const { isAuth } = useAuth();
    const userStore = useUserStore();
    const { userType } = storeToRefs(userStore);

    const link = computed(() => {
      const uType = userType.value;
      let name, label;

      if (uType === "client") {
        name = ROUTE_LANDING_NANNY_CONTRACTOR_NAME;
        label = "Jestem nianią";
      } else if (uType === "contractor") {
        name = ROUTE_LANDING_NANNY_CLIENT_NAME;
        label = "Szukam nianię";
      } else {
        throw new Error(`Wrong userType: ${uType}`);
      }

      return {
        to: {
          name,
        },
        label,
      };
    });

    return {
      link,
      isAuth,
    };
  },
};
</script>
