<template>
  <section class="section-filter">
    <div class="base-filter section-filter__filter-base">
      <InputTabGroupSlider
        v-model="list.type"
        :name="'filter-items-type'"
        :options="optionsOffersApplications"
        class="filter-items-type filter-base__filter-items-type"
      />
    </div>

    <div class="chips section-filter__chips">
      <ButtonChipSettings @click="showCtaPopup" />
      <BaseFilterChip :label="'Wroclaw'" :isDeleted="false" />
    </div>
  </section>
</template>

<script>
import { computed, reactive, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { getSlugsFromDict } from "@/helpers";
import { useUserStore } from "@/stores/user";

import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";
import useOfferNannyDictionary from "@/composables/useOfferNannyDictionary";

import InputTabGroupSlider from "../UI/Base/InputTabGroupSlider.vue";
import BaseFilterChip from "../UI/Base/BaseFilterChip.vue";
import ButtonChipSettings from "../UI/ButtonChipSettings.vue";

export default {
  components: {
    InputTabGroupSlider,
    BaseFilterChip,
    ButtonChipSettings,
  },

  props: {
    listItemsClass: String,
    listItemsType: String,
  },

  emits: [
    "update:listItemsFilter",
    "update:listItemsClass",
    "update:listItemsType",
    "popup",
  ],

  setup(props, { emit }) {
    const ctaPopupRef = ref(null);

    const list = reactive({
      type: props.listItemsType,
      class: props.listItemsClass,
    });

    const DICTIONARY = computed(() => {
      if (list.type === "offer") {
        if (list.class === "nanny") {
          const { DICTIONARY: OFFER_NANNY_DICT } = useOfferNannyDictionary();
          return OFFER_NANNY_DICT;
        }
        throw new Error(`No DICTIONARY for ${list.class} offers!`);
      }

      if (list.type === "application") {
        if (list.class === "nanny") {
          const { DICTIONARY: APPLICATION_NANNY_DICT } =
            useApplicationNannyDictionary();
          return APPLICATION_NANNY_DICT;
        }
        throw new Error(`No DICTIONARY for ${list.class} applications!`);
      }
      throw new Error(
        `No DICTIONARY for listClass "${list.class}" and listType "${list.type}"!`
      );
    });

    const userStore = useUserStore();
    const optionsOffersApplications = computed(() => {
      const userType = userStore.userType;

      if (userType === "client") {
        return getSlugsFromDict(
          ["application", "offer"],
          DICTIONARY.value.listItemsTypes
        );
      }

      if (userType === "contractor") {
        return getSlugsFromDict(
          ["offer", "application"],
          DICTIONARY.value.listItemsTypes
        );
      }

      // Default as client's version
      return getSlugsFromDict(
        ["application", "offer"],
        DICTIONARY.value.listItemsTypes
      );
    });

    const showCtaPopup = () => {
      // alert("PLEASE REGISTER FIRST");
      emit("popup", "filter");
    };

    // Way out binding
    watch(
      () => list.type,
      (newType) => {
        emit("update:listItemsType", newType);
      }
    );

    // Way in binding
    watch(
      () => props.listItemsClass,
      (newClass) => {
        list.class = newClass;
      }
    );
    watch(
      () => props.listItemsType,
      (newType) => {
        list.type = newType;
      }
    );

    return {
      ctaPopupRef,
      optionsOffersApplications,
      list,
      showCtaPopup,
    };
  },
};
</script>

<style scoped>
.section-filter {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 20px;
}
.section-filter__filter-base {
  width: 100%;
  display: flex;
  flex-flow: row;
  column-gap: 40px;
}
.section-filter__chips {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
}
.chips_filter-chip:hover {
  will-change: opacity, max-width;
}

@media (max-width: 1200px) {
  .section-filter__filter-base {
    width: 100%;
    display: flex;
    flex-flow: column;
    row-gap: 20px;
  }
  .section-filter__chips {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
  }
}
</style>
