<template>
  <section class="section-main-advantage">
    <div class="bg">
      <SvgIllustrationsLandingNannyAdvantageBgDesktop />
    </div>

    <div class="content">
      <div class="col-left">
        <div class="illustration">
          <SvgIllustrationsLandingNannyAdvNotification />
        </div>
      </div>
      <div class="col-right">
        <div class="wrapper">
          <BaseText :text="'# smart notifications'" class="tag" />
          <BaseH2 :text="'Zajmij się jakimikolwiek sprawami'" />
          <div class="text">
            <BaseText
              :text="'Nianie w okolicy dostaną powiadomione o Twoim ogłoszeniu. Wystarczy Ci tylko zapoznać się ze zgłoszeniami i skontaktować z wybranymi opiekunkami'"
            />
          </div>
        </div>
      </div>
    </div>

    <ButtonCta
      :to="{ name: ROUTE_REGISTRATION_NAME }"
      :label="'Dodaj darmowe ogłoszenie'"
      :size="'large'"
    />
  </section>
</template>

<script>
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import SvgIllustrationsLandingNannyAdvantageBgDesktop from "@/components/Svg/Illustrations/SvgIllustrationsLandingNannyAdvantageBgDesktop.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import SvgIllustrationsLandingNannyAdvNotification from "@/components/Svg/Illustrations/SvgIllustrationsLandingNannyAdvNotification.vue";

import { ROUTE_REGISTRATION_NAME } from "@/router/constants";

export default {
  components: {
    BaseH2,
    BaseText,
    SvgIllustrationsLandingNannyAdvantageBgDesktop,
    ButtonCta,
    SvgIllustrationsLandingNannyAdvNotification,
  },

  setup() {
    return { ROUTE_REGISTRATION_NAME };
  },
};
</script>

<style scoped>
.section-main-advantage {
  width: 100%;
  height: 700px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
}
.section-main-advantage .bg {
  position: absolute;
  width: 100vw;
  height: 700px;
  z-index: -1;
  overflow: hidden;
}
.section-main-advantage .content {
  padding: 200px 0px 40px;
  width: 100%;
  display: flex;
  column-gap: 40px;
  justify-content: center;
}
.col-left,
.col-right {
  width: 100%;
  display: flex;
  justify-content: center;
}
.col-left .illustration svg {
  padding-top: 15px;
  width: 415px;
}
.col-right .wrapper {
  width: 450px;
  display: flex;
  flex-flow: column;
  row-gap: 25px;
}
.col-right .tag {
  font-weight: 500;
  color: #fe881c;
}
.col-right .text {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
}
</style>
