<template>
  <svg
    viewBox="0 0 1280 698"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M352.289 0C100.743 -0.000225461 -0.5 112.5 -0.5 112.5V697.5H1285V105.1C1285 105.1 1200.09 70.5989 1142.64 60.5579C1010.45 37.4519 936.051 108.207 801.807 105.1C621.391 100.925 603.836 0.000225462 352.289 0Z"
      fill="white"
    />
  </svg>
</template>
