<template>
  <component :is="LandingView" />
</template>

<script>
import { computed } from "@vue/reactivity";

import useIsMobile from "@/composables/useIsMobile";

import LandingNannyClientDesktop from "@/views/Landing/Nanny/Client/LandingNannyClientDesktop.vue";
import LandingNannyClientMobile from "@/views/Landing/Nanny/Client/LandingNannyClientMobile.vue";
import { useUserStore } from "@/stores/user";
import { onMounted } from "vue";

export default {
  setup() {
    const { isMobile } = useIsMobile();
    const LandingView = computed(() => {
      return isMobile.value
        ? LandingNannyClientMobile
        : LandingNannyClientDesktop;
    });

    const userStore = useUserStore();
    onMounted(() => {
      userStore.userType = "client";
    });

    return { LandingView };
  },
};
</script>
