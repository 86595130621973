<template>
  <section class="section-list">
    <ListAnonymusFilter
      :list-items-filter="filter"
      @update:list-items-filter="$emit('update:filter', $event)"
      :list-items-class="itemsClass"
      @update:list-items-class="$emit('update:itemsClass', $event)"
      :list-items-type="itemsType"
      @update:list-items-type="$emit('update:itemsType', $event)"
      @popup="showPopup"
    />

    <div class="above-list-section">
      <div class="items-amount">{{ itemsTotalAmount }} ogłoszenia</div>
      <div class="items-sort">Według aktualności</div>
    </div>

    <ListAnonymusItems
      :list-items="itemsLimited"
      :list-items-class="itemsClass"
      :list-items-type="itemsType"
      @fetch-more="handleFetchMore"
      @popup="showPopup"
    />

    <ButtonCta
      :to="buttonCtaState.to"
      :label="buttonCtaState.label"
      :caption="buttonCtaState.caption"
      :size="'large'"
      class="list-cta-button"
    />

    <PopupPushToRegistration
      ref="ctaPopupRef"
      :description="popupState.description"
    />

    <!-- <div v-if="isFetching">Loading more...</div> -->
  </section>
</template>

<script>
import { computed, reactive, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

import { ROUTE_REGISTRATION_START_NAME } from "@/router/constants";

import ListAnonymusFilter from "@/components/List/ListAnonymusFilter.vue";
import ListAnonymusItems from "@/components/List/ListAnonymusItems.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import PopupPushToRegistration from "@/components/PopupPushToRegistration.vue";

export default {
  components: {
    ListAnonymusFilter,
    ListAnonymusItems,
    ButtonCta,
    PopupPushToRegistration,
  },

  props: {
    items: Array,
    itemsTotalAmount: Number,
    filter: Object,
    itemsClass: String,
    itemsType: String,
    isFetching: Boolean,
  },

  emits: [
    "update:filter",
    "update:itemsClass",
    "update:itemsType",
    "fetchMore",
  ],

  setup(props, { emit }) {
    const MAX_AD_AMOUNT_ON_LIST = 10;

    const userStore = useUserStore();
    const { userType } = storeToRefs(userStore);

    const buttonCtaState = computed(() => {
      const to = { name: ROUTE_REGISTRATION_START_NAME };
      if (userType.value === "contractor") {
        return {
          to,
          label: "Zaloż darmowe konto",
          caption: "Aby przeglądać więcej ogłoszeń i stworzyć profil",
        };
      }

      // Show button for userType === 'client' by default
      return {
        to,
        label: "Zaloż darmowe konto",
        caption: "Aby przeglądać więcej profili i dodać ogłoszenie",
      };
    });

    const itemsLimited = computed(() => {
      const { items } = props;
      if (items < MAX_AD_AMOUNT_ON_LIST) {
        return items;
      }
      return items.slice(0, MAX_AD_AMOUNT_ON_LIST);
    });

    const ctaPopupRef = ref(null);

    const popupState = reactive({
      description: "",
    });

    const handleFetchMore = () => {
      if (props.items.length >= MAX_AD_AMOUNT_ON_LIST) {
        return;
      }
      emit("fetchMore");
    };

    const showPopup = (popupType) => {
      if (ctaPopupRef.value === null) {
        return;
      }

      switch (popupType) {
        case "ad-limit":
          popupState.description =
            "Aby przeglądać więcej \nprofili i dodać ogłoszenie";
          break;
        case "wishlist":
          popupState.description = "Aby dodawać profili niań \ndo ulubionych";
          break;
        case "filter":
          popupState.description =
            "Aby korzystać z filtrów, dodawać ogłoszenia i zobaczyć więcej profili";
          break;
        default:
          throw new Error(`Wrong popupType: ${popupType}`);
      }

      ctaPopupRef.value.open();
    };

    return {
      itemsLimited,
      buttonCtaState,
      handleFetchMore,
      ctaPopupRef,
      showPopup,
      popupState,
    };
  },
};
</script>

<style scoped>
.section-list {
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
.above-list-section {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.above-list-section .items-amount,
.above-list-section .items-sort {
  font: var(--system-font);
  opacity: 0.8;
}
.section-list .list-cta-button {
  margin-top: 100px;
}
@media (max-width: 1200px) {
  .section-list {
    width: 100%;
    padding: 30px 0px 80px;
    display: flex;
    flex-flow: column;
    row-gap: 20px;
  }
  .section-list .list-cta-button {
    margin-top: 100px;
  }
}
</style>
