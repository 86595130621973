<template>
  <div class="filter-chip" :is-deleted="isDeleted">
    <label class="label filter-chip__label">{{ label }} </label>
    <BaseButtonIconCross
      v-if="isDeleted"
      @click="$emit('deleteMe')"
      class="filter-chip__cross"
    />
  </div>
</template>

<script>
import BaseButtonIconCross from "./BaseButtonIconCross.vue";
export default {
  components: { BaseButtonIconCross },

  props: {
    label: {
      type: String,
      default: "",
    },
    isDeleted: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["deleteMe"],
};
</script>

<style scoped>
.filter-chip {
  height: 45px;
  width: fit-content;
  padding: 0 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
  background-color: #fff;
}
.filter-chip[is-deleted="true"] {
  padding: 0 5px 0 20px;
  column-gap: 5px;
}
.filter-chip__label {
  font-size: 16px;
}
.filter-chip:deep(.filter-chip__cross) {
  height: 35px;
}
</style>
