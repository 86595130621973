<template>
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 375 670"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M74.5 0.648438C-14.4024 0.647736 -20 71.001 -20 71.001V634.295C-20 634.295 71.5 682.573 145.5 645.862C219.5 609.151 239.167 670.001 295.5 670.001C341.635 670.001 400 626.249 400 626.249L394.5 58.501C394.5 58.501 380 10.001 314 10.001C248 10.001 264.911 47.5011 204.5 47.501C144.089 47.5009 163.402 0.649139 74.5 0.648438Z"
      fill="white"
    />
  </svg>
</template>
