<template>
  <section class="section-main-advantage">
    <div class="bg">
      <SvgIllustrationsLandingNannyAdvantageBgMobile />
    </div>

    <div class="content">
      <div class="title-wrapper">
        <BaseText :text="'# smart notifications'" class="tag" :isSmall="true" />
        <BaseH2 :text="'Zajmij się jakimikolwiek sprawami'" class="title" />
      </div>
      <div class="illustration">
        <SvgIllustrationsLandingNannyAdvNotification />
      </div>
      <div class="text">
        <BaseText
          :text="'Nianie w okolicy dostaną powiadomione o Twoim ogłoszeniu. Wystarczy Ci tylko zapoznać się ze zgłoszeniamii skontaktować z wybranymi opiekunkami'"
        />
      </div>
    </div>

    <ButtonCta
      :to="{ name: ROUTE_REGISTRATION_NAME }"
      :label="'Zarejestruj się'"
      :size="'large'"
    />
  </section>
</template>

<script>
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import SvgIllustrationsLandingNannyAdvantageBgMobile from "@/components/Svg/Illustrations/SvgIllustrationsLandingNannyAdvantageBgMobile.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";

import { ROUTE_REGISTRATION_NAME } from "@/router/constants";
import SvgIllustrationsLandingNannyAdvNotification from "@/components/Svg/Illustrations/SvgIllustrationsLandingNannyAdvNotification.vue";

export default {
  components: {
    BaseH2,
    BaseText,
    SvgIllustrationsLandingNannyAdvantageBgMobile,
    ButtonCta,
    SvgIllustrationsLandingNannyAdvNotification,
  },

  setup() {
    return { ROUTE_REGISTRATION_NAME };
  },
};
</script>

<style scoped>
.section-main-advantage {
  padding: 90px 0px 90px;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.section-main-advantage .bg {
  position: absolute;
  top: 0px;
  z-index: -1;
  width: 100vw;
  height: 100%;
}
.section-main-advantage .bg svg {
  width: 100%;
  height: 100%;
}
.section-main-advantage .content {
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 30px;
  align-items: center;
}
.section-main-advantage .title-wrapper {
  display: flex;
  flex-flow: column;
  row-gap: 15px;
  align-items: center;
}
.section-main-advantage .title-wrapper .title {
  text-align: center;
}
.section-main-advantage .content .tag {
  padding-right: 6px;
  font-weight: 500;
  color: #fe881c;
}
.section-main-advantage .illustration svg {
  padding: 25px 0px;
  width: 100%;
}
.section-main-advantage .content .text {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  text-align: center;
}
</style>
