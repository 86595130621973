<template>
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6111 20.1145L9.49609 17.9995L3.49609 23.9995L9.49609 29.9995L11.6111 27.8845L9.24109 25.4995H31.9961V22.4995H9.24109L11.6111 20.1145Z"
      fill="#FFAA47"
    />
    <path
      d="M25.3811 15.8845L27.4961 17.9995L33.4961 11.9995L27.4961 5.99951L25.3811 8.11451L27.7511 10.4995H4.99609V13.4995H27.7511L25.3811 15.8845Z"
      fill="#FFAA47"
    />
  </svg>
</template>
